/*----------------------------------------------------------------------------------------------------------*/
/*	ACCESSIBILITY																  						  	*/
/*----------------------------------------------------------------------------------------------------------*/

#accessibilityMenuContainer	{
	border:3px solid $black; 
	background:$grey; 
	position:absolute; 
	top:-999px; 
	left:-999px; 
	opacity: 0; 
	z-index:9999;

	&.accessMenuOn {
		border:3px solid $black; 
		background:$grey; 
		position:absolute; 
		top:0px; 
		left:0px;		
	}
}

#accessibilityMenu	{
	margin:0; 
	padding:20px 10px; 
	overflow:hidden;

	li {
		margin:0 10px; 
		padding:0; 
		list-style:none; 
		float:left;

		a {
			display:block; padding:10px; border:3px solid $black;

			&:hover,
			&:focus,
			&:active {
				background-color: $black;
				color:$white;
				text-decoration: none;
			}
		}
	}
}