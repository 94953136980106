/*--------*/
/* FOOTER */
/*--------*/

	.js .toTop {
		position: absolute;
		top:20px;
		right:15px;
		width:40px;
		height:40px;
		background-color: $yellow;
		color:$grey;

		@include icomoon(arrow-up, after, true) {
			@include centerer();
			margin-top:-4px;
			@include font-size(1.8);
		}

		&:hover,
		&:focus {
			background-color: $black;
			color:$yellow;
		}

		@include mq($from: desktop) {
			top:-40px;
		}
	}

	.no-js .toTop {
		display:none;
	}


	.footer-container {

		position: relative;
		background-color: $grey;
		padding-bottom:30px;

		@include mq($from: desktop) {
			padding-top:60px;
		}

				
		.footer-col {
			margin-bottom:40px;
			text-align: center;

			@include mq($from: 0px, $until: largephone) {
				width: 100%;
				margin-left: 0;
			}
			@include mq($from: largephone, $until: desktop) {
				width: 49%;
				&:nth-child(2n+1) {
					clear: left;
					margin-left: 0;
				}
			}

			@include mq($from: desktop) {
				text-align: left;
			}
		}
		
		.title {
			margin:0;
			padding:0 0 10px;
			color:$yellow;
			@include font-size(2.1);
			text-align:center;

			@include mq($from: desktop) {
				text-align:left;
			}
		}

		p, a {
			@include font-size(1.5);
		}

		a {
			display: inline-block;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}

		.button {
			font-weight:$bold;
			@include font-size(1.5);
		}

		.no-bullets a {
			padding:3px 0;
			color:$white;
		}
	}


	.footer-newsletter-signup {

		margin-bottom:30px;
		position: relative;

		input[type="text"] {
			margin:0;
			border:0;
			padding:10px 41px 10px 10px;
			display:block;
			width:100%;
		}
		.sign-up {
			cursor:pointer;
			position: absolute;
			top:0;
			right:0;
			width:40px;
			height:100%;
			@include icomoon(arrow-right, after, true) {
				@include centerer();
				color:$grey;
				line-height:0;
			}

			&:hover:after,
			&:focus:after {
				color:$white;
			}
		}
	}



	.footer-main {		
		@include clearfix;
		padding-top:20px;

		.printer,
		.pinterest {
			display: none;
		}
	}




	// footer sub
	.footer-sub {

		@include clearfix;

		text-align: center;

		.logo {			
			display:inline-block;
			margin:0 0 20px;
			img {
				width:70px;
				height:auto;
			}
		}
		
		li, p {
			color:$white;
		}

		ul {
			position: relative;
			margin:0 10px;
			padding: 0;

			@include mq($from: desktop) {
				display:inline-block;
			}


			li {
				display: inline-block;
				vertical-align: middle;
				@include font-size(1.3);

				padding:3px 0;
				
				&:before {
					content: '|';
				}
				
				//logo
				&:first-child:before {
					content: '';
				}
				
				a {
					display: inline-block;
					padding:0 10px;
					color:$white;
					@include font-size(1.3);
				}
			}
		}
	}
	// end footer sub