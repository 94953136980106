/*-------------*/
/*	NAVIGATION */
/*-------------*/

$subNavPadding: 40px;
$subNavDarken: 5;

.primary-nav-container {

	//TOP LEVEL 
	.top-level-container {
		position: relative;
		padding-top:130px;

		.top-level-background {
			position:absolute;
			top:0;
			right:0;
			width:100%;
			@include mq($from: desktop) {
				width:90%;
			}
			height:100%;
			background-color: $grey;
		}

		.top-level-background-triangle {
			
			display:none;
			@include mq($from: desktop) {
				display:block;
			}

			position:absolute;
			top:-50%;
			left:0;
			width:10%;
			height:150%;
			background-color: $grey;
			@include transform(rotate(10deg));
			@include transform-origin(0% 100%);
		}
	}  //.top-level-container



	//BOTTOM LEVEL
	.bottom-level-container {
		position: relative;
		@include mq($from: desktop) {
			padding-bottom:100px;
		}

		.bottom-level-background {
			position:absolute;
			top:0;
			right:0;
			
			width:100%;
			@include mq($from: desktop) {
				width:60%;
			}
			
			height:100%;
			background-color: $grey;
		}
		
		.bottom-level-background-triangle {

			display:none;
			@include mq($from: desktop) {
				display:block;
			}

			position:absolute;
			top:0;
			left:0%;
			width:50%;
			height:500%;
			background-color: $grey;
			@include transform(rotate(-65deg));
			@include transform-origin(0% 0%);
		}
	}  //.bottom-level-container



	//Show the the 1st level nav and its 1st level children
	#main-menu  {

		position: relative;		

		@include mq($from: desktop) {
			float:right;
			width:90%;
			margin-bottom:50px;
		}
		
		a, .back-link {
			color:$white;
			@include font-size(1.60);
			display: block;
			position: relative;
			padding:15px;
			cursor: pointer;
			// border:1px solid red;

			@include mq($from: desktop) {
				padding-left:0;
			}

			&:hover,
			&:focus {
				text-decoration: underline!important;
			}
		}

		a {
			padding-right: 50px;
		}

		li.menu-parent-link > a {
			@include font-size(2.3);						

			&:focus,
			&:hover {
				color:$white;
				text-decoration: underline!important;	
			}
		} 

		//hide all the sub navs, we want to control their appearance
		.subnav-contents {
			//display:none;
			width: 100%;
			display: block;
        	position: absolute;
			left: -100%;
			top: 0;
			transition: left .25s ease-in-out;
			background-color: $grey;
			padding-right: $subNavPadding;

			&.menu-expanded {
				left: 0;
				@extend .highestZ;
			}

			> .menu-back {
            	background-color: darken($grey, $subNavDarken);
            }

            
            .back-bar {
            	width: $subNavPadding;
            	height: 100%;
            	position: absolute;
            	top: 0;
            	right: 0;
            	box-shadow: 2px 1px 3px 0 rgba(0,0,0,.4);

            	@include icomoon(arrow-left, before){
					position: absolute;
					top:5px;
					left:0px;
					width:40px;
					height:40px;
					text-align:center;
					z-index:9999;
					font-size:20px;
					line-height:40px;
					font-weight: normal;
					cursor: pointer;
					color: $white; 
				}


            }

            @include mq($from: desktop) {
            	.menu-back {
            		display: none;
            	}
            }

			// Nested loop to increment sub menu padding
			$sel: '';
		    @for $i from 2 through 5 {
		        $sel: if($i == 2, '.subnav-contents', selector-nest($sel, '.subnav-contents')); 

		        #{$sel} {
		            //padding-right: $subNavPadding * $i;

		            > .menu-back {
		            	background-color: darken($grey, $subNavDarken*$i);
		            }
		        }
		    }

			@include mq($from: desktop) {
				display: none;
				position: relative;
				left: auto;
				top: auto;
				padding-right: 0;
				background-color: transparent; 


				&.menu-expanded {
					left: auto;
				}
			}

			.current a {
				@include mq($until: desktop) { 
					color:$grey;
					background-color: $yellow;
				}
			}

			.menu-parent-link {
				display: none;

				@include mq($until: desktop) { 
					display: block;
				}
			}


		}
		
		> ul {  //very top level UL
			
			> .has-children > .subnav-contents {
				//top: 0;
			}
			
			li {
				
				position: relative;


			}
			// border:1px solid lime;
			
			> li {
				
				// border:1px solid red;

				@include mq($from: desktop) {
					vertical-align: top;
					display: inline-block;
					width:18%;
					margin-left:2.5%;
					
					&:first-child {
						margin-left:0;
						// border:1px solid yellow
					}
				}


				> .nav-name {
					
					font-weight: $light;
					// background:black;

					a {
						color:$yellow;
						@include font-size(2.3);						

						&:focus,
						&:hover {
							color:$white;
							text-decoration: underline!important;	
						}
					}

					&.current a {
						@include mq($until: desktop) { 
							color:$white;
							text-decoration: underline!important;
						}
						
					}
				}

				> .subnav-contents {
					.nav-name:before {
						top:4px;
						right: 2.5%;
						// border:1px solid lime;
					}

					@include mq($from: desktop) {
						display:block;
						margin-left:0;
					}
				}
			}
		}
	} //#main-menu



	//all LI's that have children
	.has-children {
		

		// apply to direct nav name
		> .nav-name {
			position: relative;
			overflow:hidden;

			@include mq($from: desktop) {
				&:before {
					display:none;
				}
			}

			
			//Show +/- at desktop
			@include icomoon(plus, before)
			{
				border:1px solid white;
				border-radius:3px;
				position: absolute;
				top:10px;
				right:2.5%;
				width:40px;
				height:40px;
				text-align:center;
				z-index:9999;
				font-size:30px;
				line-height:40px;
				cursor: pointer;
				color: $white;
			}

			&.current:before {
				color: $grey;
				border-color: $grey;
			}


		}

		
		// when sub nav is expanded
		&.active > .nav-name {
				
			@include icomoon(minus, before)
				{
					font-size:30px;
					line-height:40px;
				}
		}
	
	} //has-children


	.keep-in-touch-container {

		padding:20px 0;
		text-align:center;
		color:$white;
		overflow: hidden;
		
		@include mq($from: desktop) {
			border-top:1px solid $lightgrey;
			@include mq($from: desktop) {
				border-bottom:1px solid $lightgrey;
			}
			padding:20px 30px;
			text-align:left;
			float:right;
			width:90%;
		}

		
		.social-container {
			// border:1px solid red;
			margin-bottom:20px;
			

			@include mq($from: desktop) {
				margin-bottom:0;
			}

			p {
				display:block;
				padding-bottom:5px;
			}

			ul {
				display:inline-block;
				vertical-align: middle;
			}
			
			@include mq($from: desktop) {
				float:left;
				
				p {
					display:inline-block;
					vertical-align: middle;
					padding:0;
				}
			}
		}  //.social-container


		
		.newsletter-container {

			overflow:hidden;

			p {
				padding-bottom:5px;
			}

			@include mq($from: desktop) {
				
				float:right;

				p {
					display:inline-block;
					vertical-align: middle;
					margin-right:10px;
					padding:0; 
				}
			}


			.header-newsletter-signup {

				display:block;
				position:relative;

				@include mq($from: desktop) {
					display:inline-block;
					vertical-align: middle;
				}

				input[type="text"] {
					border:0;
					margin:0;
					padding:10px 50px 10px 10px;
					display:inline-block;
					vertical-align: middle;
					width:100%;
				}
				.sign-up {
					cursor:pointer;
					position: absolute;
					top:0;
					right:0;
					width:40px;
					height:100%;
					@include icomoon(arrow-right, after, true) {
						@include centerer();
						color:$grey;
						line-height:0;
					}

					&:hover:after,
					&:focus:after {
						color:$white;
					}
				}  //.sign-up

			}  //.header-newsletter-signup
		
		}  //.newsletter-container
	
	}  //.keep-in-touch-container



}  //.primary-nav-container


.js .primary-nav-container {

	display:none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1500;
	@include transition(transform $fast-anim);
	overflow:hidden;
	padding:0;

	@include transform(translate3d(100%, 0, 0));  //position it offscreen

	&.show {
		display:block;
	}
	&.active {
		@include transform(translate3d(0, 0, 0) !important);  //move into view when it's active
	}
	
	// lt-ie9 override - don't ever show
	.ie9 &,
	.lt-ie9 & {
		@include transform(none !important);
		display: none;
	}
}


.no-js .primary-nav-container {
	display:block
}