/*------------------------------------------------------------------------------------------------------------*/
/*	NEW DEFAULTS															  						  	  	  */
/*------------------------------------------------------------------------------------------------------------*/
	
	article, aside, details, 
	figcaption, figure, 
	footer, header, hgroup, 
	menu, nav, section {
		display:block;
	}

	
	table {
		border-collapse:collapse;
		border-spacing:0;
	}
	
	hr {
		display:block;
		height:1px;
		border:0;
		border-top:2px solid lighten($grey, 40%);
		margin:1em 0;
		padding:0;
	}
	
	img {
		-ms-interpolation-mode: bicubic;
	}


	address {			
		font-style:normal; 
		padding-bottom:1em
	}

	

	// Links
		a {
			margin:0;
			padding:0;
			vertical-align:baseline;
			background:transparent;
			text-decoration:none;
			-webkit-tap-highlight-color: rgba(0,0,0,0);

			&:hover,
			&:active {
				outline:none;
				-webkit-tap-highlight-color: rgba(0,0,0,0);
				text-decoration: underline;
			}

			&:focus {
				outline:1px solid $teal;
			}

			img {
				border:0;
				vertical-align:middle;
				-moz-backface-visibility:hidden;				
			}
		}

	
	
	// Lists
		ul, 
		ol {
			margin:0 auto;
			padding: 0;
			padding-left: 20px;
		}
		
		ol {
			list-style-type:decimal;
		}
		
		nav {
			ol,
			ul,
			li {
				margin:0;
				padding:0;
				list-style-type:none;
				list-style-image:none;				
			}
		}

	

	// Text
		pre,
		code,
		kbd,
		samp {
			font-family:monospace, sans-serif;
		}
		
		q,
		blockquote {
			quotes:none;

			&:before,
			&:after {
				content:''; content:none;
			}
		}
		
		
		ins {
			background-color:#ff9;
			color:#000;
			text-decoration:none;
		}
		
		mark {
			background-color:#ff9;
			color:#000;
			font-style:italic;
			font-weight:bold;
		}
		
		del {
			text-decoration:line-through;
		}
		
		abbr[title],
		dfn[title] {
			border-bottom:1px dotted #ccc;
			cursor:help;
		}
		
		sub {
			vertical-align:sub;
			font-size:smaller;
		}
		
		sup {
			vertical-align:super;
			font-size:smaller;
		}
		
		small {
			font-size:85%;
		}

		.ie7 .radioLabel, .ie7 .checkBoxLabel,
		.ie8 .radioLabel, .ie8 .checkBoxLabel,
		.ie9 .radioLabel, .ie9 .checkBoxLabel {
			vertical-align:top;
		}


