/*---------*/
/*	WYSWIG */
/*---------*/

	.accordion .contentDetails,
	.wysiwyg {
		h2 {margin-top: 0;}
		
		ul, ol {margin: 10px 0 15px 0;}
		li {margin-bottom: 8px;}
		p, li {
			line-height: 1.3;
		}
		
		hr {margin: 30px 0;}
	}