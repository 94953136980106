/*------*/
/* GRID */
/*------*/

	.row {
		@include clearfix;
		margin-bottom: 30px;
	}

	$columns: 12;
	$col-width: 100/$columns;
	$col-width-percentage: percentage($col-width/100);
	$width-w-margin: $col-width - 0.2;
	$general-margin: 2;

	$width-w-gen-margin: ($col-width - $general-margin) + ($general-margin / $columns);

	[class*=" colWidth-"],
	[class^="colWidth-"] {
		float: left;
		margin-left: percentage($general-margin/100);
		
		&:first-child {
	        margin-left: 0 !important;
	    }
	    
	    &.no-margin {
	    	margin: 0;
	    }
	}


	$counter: 0;
	$width: 0;

	@for $i from 1 through $columns {
		@if $i == 1 {
			$width: $width-w-gen-margin;
		} @else {
			$width: $width + $width-w-gen-margin + $general-margin;
		}
		$counter: $counter + $width;
		
		
		.colWidth-#{$i} {
			width: percentage($width/100);
	        
	        &.no-margin {
	        	width: $col-width-percentage * $i;
	        }
		}
	}


	.colWidth-4 {
		width:100%;
		@include mq($until: largephone) {
			margin-left: 0;
		}

		@include mq($from: largephone, $until: tablet) {
			width:48%;
			&:nth-child(2n + 1) {
				margin-left:0!important;
				clear:left;
			}
		}

		@include mq($from: tablet) {
			width:32%;

			&:nth-child(3n + 1) {
				clear:left;
				margin-left:0!important;
			}
		}
	}












	.grid {
		@include clearfix;
		position: relative;
		z-index:1000;
	}

	$grids: 4;
	$marginBase: 3;

	.grid__item {
		position: relative;
		width: 100%;

		float: left;
	}


	.grid--4 {
		
		.grid__item {
			width:100%;

			@include mq($from: tablet, $until: desktop) {
				width:50%;
				&:nth-child(2n + 1) {
					clear:left;
				}
			}

			@include mq($from: desktop) {
				width:25%;

				&:nth-child(4n + 1) {
					clear:left;
					
				}
			}
			
		}
	}


	.grid--2 {
		
		.grid__item {
			width:100%;

			@include mq($from: smalldesktop) {
				width:50%;

				&:nth-child(2n + 1) {
					clear:left;
				}
			}
			
		}
	}

	div.grid__item.promo-spotlight{
		min-height:360px;
		//height:34vh;
	}
	
	div.grid__item div {
		min-height: 360px;
		height:30vh;
	}