/*------------*/
/*	SPOTLIGHT */
/*------------*/

	//Traditional spotlight with image at the top, text container underneath, could be side by side
	.spotlight-container {

		.spotlight {
			color:$grey;
			margin-bottom:20px;
				
			.image-container {
				
				img  {
					width:100%;
					height:auto; 
				}
			}

			.text-container {
				background-color: $grey;
				padding:$mobile-padd;
				
				
				.title {
					margin:0;
					padding-bottom:20px;
					color:$grey;
					@include font-size(2.1);
					font-weight:$regular;
				}

				p {
					color:$grey;
					font-weight:$regular;
				}

				.more {
					@include font-size(1.8);
					display:block;
					text-align: right;
					position: relative;
					padding-right:20px;
					color:$black;
					font-weight:$regular;
					
					@include icomoon(arrow-right, after) {
						position: absolute;
						top:-2px;
						right:0;
						margin-left:5px;
						margin-top:4px;
					}
				}
			
			}//text-container
			
			a {
				&:hover,
				&:focus {
					
					text-decoration: none;
					
					.text-container {
						background-color: $black;

						.title {
							color:$white;
						}
						p {
							color:$white;
						}
						.more {
							color:$white;
						}
					}
				}
			}//a
		
		}//spotlight

	}//spotlight container





	//Dartmoor story spotlight
	.story-container {
		@include clearfix();
		margin:40px 0;

		a:hover {
			text-decoration: none;
		}
	}

	.spotlight-story {
		margin-bottom:50px;		
		position: relative;
		h2 {
			margin:0;
			padding:7px 0;
			position: absolute;
			top:-20px;
			left:50%;
			margin-left:-45%;
			width:90%;
			@include font-size(1.9);
			text-align: center;
			color:$white;
		}
		.image-container {
			img  {
				display:block;
				width:100%;
				height:auto;
			}
		}
		.bottom-container {
			border:2px solid $grey;
			border-top:0;
			padding:4px;
			.text-container {
				padding:20px 20px 0;
				text-align: center;
			}
			.icon-container {
				
				h3 {
					margin:0;
					padding:0;
					font-weight:$bold;
					@include font-size(1.7);
					text-align: center;
				}
				.story-icons {
					margin:20px auto;
					padding:0;
					list-style: none;
					border-collapse: seperate;
					text-align:center;
					
					li {
						display: inline-block;						
						@include font-size(1.2);
						text-align: center;
						color:$white;

						a {
						display: table-cell;
						vertical-align: middle;
							width:80px;
						height:80px;
							color:$white;
							text-decoration: none;
							border:2px solid white;
							@include transition(all, $fast-anim);

							&:hover,
							&:focus {
								border:2px solid $black;
							}

						}
					}
				}
			}
			.info-panel {
				padding:10px 30px;
				text-align: center;
					color:$white;
				h3 {
					margin:0 0 7px;
					padding:0;
					text-transform:uppercase;
					font-weight:$bold;
					@include font-size(1.5);
					text-align: center;
				}
				p {
					margin:0;
					padding:0;
					@include font-size(1.45);
				}
			}
		}
	}



	//-----------------
	//Angled Spotlights
	//-----------------
	
		//Base Styles
			.spotlight-full-width {
				overflow:hidden;		
				@include clearfix();
				position: relative; 
				// border:3px solid red;

				@include mq($from: largephone) {
					width:75%;
				}

				@include mq($from: smalldesktop) {
					width:50%;
				}
				

				&.spotlight-internal {
					width:100%;
					@include mq($from: largephone) {
						width:75%;
					}
					@include mq($from: tablet) {
						width:60%;
					}

					@include mq($from: smalldesktop) {
						width:100%;
					}


					@include mq($from: desktop) {
						width:75%;
					}

					@include mq($from: largedesktop) {
						width:60%;
					}
				}

				.background {
					@include clearfix();
					// border:6px solid lime;

					.internal {
						position: relative; //fixes z-index issues
						padding:1px 20px 30px;
						// border:3px solid cyan;
						display:table-cell;
						vertical-align: middle;

						@include mq($from: desktop) {
							// width:400px;
						}

						.title {
							font-weight:$light;
						}
					}

					.triangle {
						position: absolute;
						height:150%;
						width:250px;
						// border:3px solid black;
					}
				}

			}

		

		//Spotlight on the LEFT
			.spotlight-full-width-left {

				float:left;

				@include mq($from: largephone) {
					padding-right:150px;
				}

				@include mq($from: smalldesktop) {
					padding-right:190px;
				}
				
				.background {  //this is where we apply our colour classes

					.internal {
						// float:right;
						padding:20px;
					}
				}

				&.spotlight-internal {
					.background {  //this is where we apply our colour classes

						.internal {
							width:100%;

							@include mq($from: desktop) {
								width:auto;
							}
						}
					}
				}
			}

			.spotlight-internal {

			}

		

		//Spotlight on the RIGHT
			.spotlight-full-width-right {

				float:right;

				@include mq($from: largephone) {
					padding-left:150px;
				}

				@include mq($from: smalldesktop) {
					padding-left:190px;
				}

				.background {  //this is where we apply our colour classes

					.internal {
						// float:left;	
						padding:20px;						
					}			
				}

				&.spotlight-internal {
					.background {  //this is where we apply our colour classes

						.internal {
							width:100%;

							@include mq($from: desktop) {
								width:auto;
							}
						}
					}
				}
			}




		//Triangle points towards the classname i.e. top right
			.triangle-topRight {
				bottom:0;
				@include transform-origin(100% 100%);
				
				@include mq($from: largephone) {
					@include transform(rotate(15deg));
					right:150px;
				}

				@include mq($from: smalldesktop) {
					@include transform(rotate(20deg));
					right:190px;
				}
			}

			.triangle-bottomRight {
				top:0;
				@include transform-origin(100% 0%);
				
				@include mq($from: largephone) {
					@include transform(rotate(-15deg));
					right:150px;
				}

				@include mq($from: smalldesktop) {
					@include transform(rotate(-20deg));
					right:190px;
				}
			}

			.triangle-topLeft {
				bottom:0;			
				@include transform-origin(0% 100%);
				
				@include mq($from: largephone) {
					@include transform(rotate(-15deg));
					left:150px;
				}

				@include mq($from: smalldesktop) {
					@include transform(rotate(-20deg));
					left:190px;
				}
			}

			.triangle-bottomLeft {
				top:0;			
				@include transform-origin(0% 0%);
				
				@include mq($from: largephone) {
					@include transform(rotate(15deg));
					left:150px;
				}

				@include mq($from: smalldesktop) {
					@include transform(rotate(20deg));
					left:190px;
				}
			}
	//---------------------
	//END Angled Spotlights
	//---------------------


	



	//Promo spotlights, where the text container is the main container with a background image
	.promo-spotlight-container {

		@include clearfix();
		
		.promo-spotlight {

			position: relative;
			width:100%;
			min-height:250px;

			@include mq($from: tablet, $until: desktop) {
				width:50%;
				min-height:360px;
			}

			@include mq($from: desktop) {
				width:25%;
				min-height:360px;
			}



			a.hover {
				text-decoration:none;
				display:block;
				@include transition(all, $fast-anim);

				&:before {
					content:'';
					position: absolute;
					top:0;
					left:0;
					right:0;
					bottom:50%;
					background: -moz-linear-gradient(top,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
					background: -webkit-linear-gradient(top,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
					background: linear-gradient(to bottom,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
				}


				&:hover,
				&:focus {
					&:before {
						bottom:0;
						background:rgba(0,0,0,0.45)
					}
					.title {
						color:$white;
					}
				}
			}
			.text-container {
				position: relative;
				padding:0 0 80px;
				//min-height:350px;


				&.video-row {
					@include mq($from: smalldesktop) {
						min-height:500px;
					}
				}


				//when 2 promos are stacked on top of each other inside an ordinairy promo "width" box
				&.text-container-stacked {
					min-height:250px;
				}

				&.no-button {
					padding-bottom:25px;
				}


				.title {
					padding:25px;
					@include font-size(3,3.3);
					font-weight:$light;

					.lt-ie10 &{
						background: -moz-linear-gradient(top,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
						background: -webkit-linear-gradient(top,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
						background: linear-gradient(to bottom,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
						
						&.noShade {
							filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
						}
					}

					
					&.larger {
						@include font-size(3.2,3);
						padding-bottom:0.8em;
					}
				}

				.more-container {
					position: absolute;
					bottom:20px;
					left:0;
				}

			}

		}		
	}


	//Twitter promo spotlight
	.social-message-container {

		position: relative;
		height:100%;

		&:before,
		&:after {
			content:"";
			position: absolute;
			top:0;
			left:0;
			width:100px;
			height:100px;
			background-image:url(/assets/images/design/left-quote.png); 
			background-position:50% 50%;
			background-repeat:no-repeat; 
			-webkit-background-size:contain;
			-moz-background-size:contain;
			-o-background-size:contain;
			background-size:contain;
		}

		&:after {
			top:auto;
			left:auto;
			bottom:0;
			right:0;
			@include transform-origin(50% 50%);
			@include transform(rotate(180deg));
		}

		.tweet {
			padding:50px;

			img {
				float:left;
				margin-right: 20px;
			}

			.twitter-text {
				overflow:hidden;
				
				p {
					@include font-size(2.2, 2); 
					font-weight:$light;
				}

				.twitter-name {
					@include font-size(1.7); 
					font-weight:$bold;
				}
				.twitter-handle {
					font-weight:$light;
					color:$lightgrey;
				}

			}





		}
	}


	//Text only, no images, usually a background fill colour
	.spotlight-text-only {
		.internal {
			padding:20px 40px;
			display:table-cell;
			vertical-align: middle;

			@include mq($from: tablet) {
				width:75%
			}

			@include mq($from: largedesktop) {
				width:60%
			}
		}
	}



	//Funzone spotlights	
	.funzone_spotlight {
		
		div {
			
			display:table;
			width:100%;

			@include mq($until: largephone) {
				background-image:none!important;
				// border:1px solid red;
			}
			@include mq($from: largephone) {
				min-height:450px!important;
				height:auto;
				// border:1px solid lime;
			}
		}

		//need a wrapper as floated elements don't support display:table
		.funzone-spotlight-wrapper {
			
			@include mq($from: largephone) {
				
				&.right {
					float:right;
					width:50%;

					&.extended-width {
						width:65%;
					}
				}

				&.left {
					float:left;
					width:50%;

					&.extended-width {
						width:65%;
					}
				}
				
			}
		}

		

		.funzone-spotlight-content {
			padding:20px;
			display:table-cell;
			vertical-align: middle;

			@include mq($from: largephone) {
				padding:20px 40px;
			}
			
			h2 {
				font-family:$funzone-font;
				@include font-size(3)
			}
			p {
				@include font-size(2, 2.3)
			}
		}
	}




	//Planning spotlights

	a > .planning-spotlight  {
		&:hover,
		&:focus {
			background-color: #555;

			h2 {
				color:$white;
			}
		}
	}

	.planning-spotlight {
		margin-bottom:20px;
		text-align: center;
		border:3px solid black;
		padding:0 0 30px;
		
		width:100%;

		.vertCenter {
			height:100%;
			position: relative;
			
			h2 {
				position: relative;
				margin:30px;
				padding:0;
				@include font-size(2.5);
				text-align: center;
			}
			img {
				display:block;
				margin:0 auto 30px;
			}
		}
	}

	div.tweet {
		.twitter-text {
			min-width: 170px;
		}
	}
/*
	.funzone-spotlight-wrapper {
		padding: 10px;
		h2, p{
		padding: 10px;
		background: rgba(255,255,255,0.5);
		}
		h2{margin-bottom: 0px;
		}
		p{margin-top: 0px;}
	}
	*/
