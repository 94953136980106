/*-------*/
/*  TYPE */
/*-------*/

    h1, h2 {
        letter-spacing: 1px;
    }

    h1, h2, h3 {
        padding-bottom:0;
        font-weight: $regular;
        text-align: left;
    }
    h4, h5, h6 {
        font-weight: $regular;
    }

    h1, .styleAsH1 {                            
        @include font-size(3.2, 3.5); 
        margin-bottom:20px;
    }

    h2, .styleAsH2 {
        @include font-size(2.7, 3);
        margin-top:30px;
        margin-bottom:10px;
    }

    h3, .styleAsH3 {
        @include font-size(2.4, 3.4);
        margin-bottom:15px;
    }

    p {             
        @include font-size($base-font-size, 2.1);
        padding-bottom:1em;
        font-weight:$regular;

    }

    /* Presentation */
    @include placeholder {
        line-height:1.2em;
        font-weight: $regular;
        color:$placeholder-grey;
    }

    .funzone-font {
        font-family: $funzone-font;
    }


    body .dartmoor-story-font {      
        font-family: $texgyreadventorregular-font;

        h1, h2, h3, 
        p, a, li {
            font-family: $texgyreadventorregular-font!important;
        }
    }