/*----------------------------------------------------------------------------------------------------------*/
/*	BUTTONS																		  						  	*/
/*----------------------------------------------------------------------------------------------------------*/

	.button-container {
		@include clearfix;
		margin-bottom:40px;

		.button {
			margin-bottom:5px;
		}

		&.center {
			text-align: center;
		}

		.float-right {
			margin-right:0;
		}
	}
		
	button,
	input[type="submit"] {
		display:inline-block; 
		border:0;
		cursor: pointer;

		&:hover,
		&:focus {
			outline:0;
		}
	}
	
	.button {
		border:1px solid $white;
		background-color: white;
		display: inline-block;
		padding: 10px 15px;
		color: $grey;
		text-align: center;
		@include font-size(1.7,1.9);
		font-weight:$regular;
		text-decoration: none!important;
		
		&:hover,
		&:focus {
			background-color: $black;
			color: white;
		}

	}
	.button-yellow {
		border:1px solid $yellow;
		background-color: $yellow;
		color:$grey;

		&:hover,
		&:focus {
			background-color: $black;
			color: $yellow;
		}
	}

	.button-lilac {
		border:1px solid $lilac;
		background-color: $lilac;
		color: $white;

		&:hover,
		&:focus {
			background-color: $white;
			color: $lilac;
		}
	}

	.button-blue {
		border:1px solid $blue;
		background-color: $blue;
		color: $white;

		&:hover,
		&:focus {
			background-color: $white;
			color: $blue;
		}
	}

	.button-grey {
		border:1px solid $grey;
		background-color: $grey;
		color: $yellow;

		&:hover,
		&:focus {
			background-color: darken($yellow, 10%);
			color: $grey;
		}
	}

	.button-green {
		border:1px solid $green;
		background-color: $green;
		color: $white;

		&:hover,
		&:focus {
			background-color: $white;
			color: $green;
		}
	}


	//button with triangele
	.more-container {
		overflow:hidden;
		display: inline-block;
		padding-right:30px;

		//Base styling
		.more {
			position: relative;
			background-color: white;
			display: inline-block;
			padding: 10px 15px;
			color: $grey;
			text-align: center;
			@include font-size(1.5);
			font-weight:$regular;
			text-decoration: none!important;
			text-transform: uppercase;

			&:after {
				background-color: white;
				content:"";
				display:block;
				position: absolute;
				bottom:0;
				right:-20px;
				width:20px;
				height:150%;
				@include transform(rotate(20deg));
				@include transform-origin(0% 0%);
			}
			
			// &:hover,
			// &:focus {
			// 	background-color: $black;
			// 	color: white;

			// 	&:after {
			// 		background-color: $black;
			// 	}
			// }
		}		
		.more-green {
			background-color:$green;
			color:$white;
			&:after {
				background-color:$green;
			}
			// &:hover,
			// &:focus {
			// 	background-color: $white;
			// 	color: $green;

			// 	&:after {
			// 		background-color: $white;
			// 	}
			// }
		}

		.more-yellow {
			background-color:$yellow;
			color:$grey;
			&:after {
				background-color:$yellow;
			}
			// &:hover,
			// &:focus {
			// 	background-color: $black;
			// 	color: $yellow;

			// 	&:after {
			// 		background-color: $black;
			// 	}
			// }
		}

		.more-lilac {
			background-color:$lilac;
			color:$white;
			&:after {
				background-color:$lilac;
			}
			// &:hover,
			// &:focus {
			// 	background-color: $white;
			// 	color:$lilac;

			// 	&:after {
			// 		background-color: $white;
			// 	}
			// }
		}

		.more-blue {
			background-color:$blue;
			color:$white;
			&:after {
				background-color:$blue;
			}
			// &:hover,
			// &:focus {
			// 	background-color: $white;
			// 	color:$blue;

			// 	&:after {
			// 		background-color: $white;
			// 	}
			// }
		}

		.more-grey {
			background-color:$grey;
			color:$white;
			&:after {
				background-color:$grey;
			}
			// &:hover,
			// &:focus {
			// 	background-color: $white;
			// 	color:$grey;

			// 	&:after {
			// 		background-color: $white;
			// 	}
			// }
		}
	}

	.play-button {
		@include centerer(55%, 50%);
		@include icomoon(play-button-circle, after) {
			color:$white;
			@include font-size(10);
			@include opacity(0.85);
			@include transition(all, $fast-anim)
		}

		&:hover,
		&:focus {
			&:after {
				@include opacity(1)
			}
		}
	}




	
