/*------------*/
/*	VARIABLES */
/*------------*/

	//development var
		$rrdev: false;

	//unicode chars
		$pound: "\00A3";


	// colors
		$white: #fff;		
		$lightgrey:#b5bbbf;
		$mediumgrey:#6a777f;
		$grey:#45555f;
		$darkgrey:#313e47;
		$black:#000000;
		$yellow:#ffc61e;
		$blue:#007199;
		$light-blue:#02b4d8;
		$teal:#007f99;
		$lilac:#7f6689;
		$green:#618002;
		$purple:#681e5b;
		$red:#e40a57;
		$darkRed:#581724;

		
		$text-grey: $grey;
		.text-grey {color:$grey};

		$text-teal: $teal;
		.text-teal {color:$teal};
		
		$placeholder-grey: lighten($black, 40%);

	//Backgrounds
		.bkgnd-white {
			background-color: $white;
			.triangle {
				background-color: $white;
			}
		}
		
		.bkgnd-light-grey {
			background-color: $lightgrey;
			.triangle {
				background-color: $lightgrey;
			}
		}

		.bkgnd-medium-grey {
			background-color: $mediumgrey;
			.triangle {
				background-color: $mediumgrey;
			}
		}

		.bkgnd-grey {
			background-color: $grey;
			.triangle {
				background-color: $grey;
			}
		}
		
		.bkgnd-yellow {
			background-color: $yellow;
			.triangle {
				background-color: $yellow;
			}
		}

		.bkgnd-light-blue {
			background-color: $light-blue;
			.triangle {
				background-color: $light-blue;
			}
		}

		.bkgnd-teal {
			background-color: $teal;
			.triangle {
				background-color: $teal;
			}
		}

		.bkgnd-blue {
			background-color: $blue;
			.triangle {
				background-color: $blue;
			}
		}

		.bkgnd-green {
			background-color: $green;
			.triangle {
				background-color: $green;
			}
		}

		.bkgnd-lilac {
			background-color: $lilac;
			.triangle {
				background-color: $lilac;
			}
		}

		.bkgnd-purple {
			background-color: $purple;
			.triangle {
				background-color: $purple;
			}
		}

		.bkgnd-red {
			background-color: $red;
			.triangle {
				background-color: $red;
			}
		}

		.bkgnd-darkRed {
			background-color: $darkRed;
			.triangle {
				background-color: $darkRed;
			}
		}

		

	//URLs
	//Use:
	// background-image:url(#{$path-design}breadcrumb-divider.png);
		$path-design: '../images/design/';
		$path-content: '../images/content/';
		$path-form-images: '../css/formImages/';
		$path-to-fonts: '../fonts/';
		$path-to-icomoon: $path-to-fonts + 'icomoon/fonts/';



	//Widths
		$page-width: 1180px;
		
		$left-col-width: 250px;
		$right-col-width: 250px;
		
		$col-spacing:30px;
		$global-min-width: 320px;
		
		$mobile-padd: 25px;
		$normal-padd: 30px;
		
		$border-base-width: 3px;
		$rad: 10px; // default border rad

	//Fonts
		$light:				300;
		$regular:			400;
		$bold:				700;

		$base-font:						'lato', sans-serif;
		$funzone-font:					'riffic_freebold';
		$texgyreadventorregular-font:	'texgyreadventorregular';
		$base-font-size:				1.7;

	//anim
		$fast-anim: 0.3s;
		$mid-anim: 3.6s;
		$slow-anim: 0.9s;
		
		
	// Variables for mq
	//large desktop applies to full bleed containers
	$mq-breakpoints: (
		mobile:			320px,
		smallphone:  	480px,
		largephone: 	600px,
		tablet:  		768px,
		smalldesktop: 	960px,
		desktop: 		$page-width,
		largedesktop:	1400px,
		maxdesktop:		1900px
	);

	
	@if $rrdev {
		$mq-show-breakpoints: ('mobile', 'smallphone', 'largephone', 'tablet', 'smalldesktop', 'desktop');
	}


	//Z-index
	.highZ {
		z-index: 1000;
	}

	.highestZ {
		z-index: 99999;
	}