/*----------------------------------------------------------------------------------------------------------*/
/*   STRUCTURE																	  						  	*/
/*----------------------------------------------------------------------------------------------------------*/

	*  {
		@include border-box;
	}

	html {
		margin:0;
		padding:0;
		background:white;
		font-size:62.5%;
		-webkit-tap-highlight-color: #f3f5f6;
		-webkit-text-size-adjust: 100%; 
		-ms-text-size-adjust: 100%;
		-webkit-font-smoothing: antialiased;
		
		overflow-x: hidden;
		&.lt-ie9 {
			overflow-x: visible;
		}
	}
	
	body {
		@include font-size($base-font-size, 1.9);
	    font-family: $base-font;
	    font-weight: $regular;
	    color:$grey;
		margin:0;
		padding:0;
		width:100%;
		background:white;
		overflow-x: hidden;
		-ms-touch-action: manipulation !important;
		min-width: $global-min-width;
		
		.lt-ie9 & {
			min-width: $page-width;
			overflow-x: visible;
		}
	}
	
	.main-wrap {
		position: relative;
	}
		
			
	.width-screen {
		position:relative;
		margin:0;
		padding:0;
		width:100%;
	}
	
	.width-page {
		position:relative;
		margin:0 auto;
		padding:0;
		@include clearfix;
		max-width: $page-width;
		// border:2px dashed cyan;
		width:95%; 

		@include mq($from: tablet) {			
			width:98%; 
		}

		@include mq($from: desktop) {
			width:100%; 
		}

	}

	.width-page.width-page-full {
		width: 100%;
	}

	.left-menu-container {
		position:relative;
		float:left;
		width:$left-col-width;
		margin-right:$col-spacing;
		margin-bottom:40px;
		padding:0;
		display:none;

		@include mq($from: 1180px) { //correction added not to show together with mobile menu
			display:block;
		}

		&.permanent {
			@include mq($until: smalldesktop) {
				display:block;
				float:none;
				width:auto;
				margin-right:0;
			}
		}
	}

	.content-container {		
		position:relative;
		overflow:hidden; 
	
		.main-content {			
			position:relative; 
			@include clearfix; 

			/* right col only */
			&.has-right-col {
				float:left; 
				width:$page-width - ($right-col-width + $col-spacing);
			}  
			
			/* left & right col */
			&.has-both-cols {
				float:left;
				width:$page-width - ( ($left-col-width + $col-spacing) + ($right-col-width + $col-spacing) );
			}
		}

		.right-col-container {
			position:relative; 
			float:right;
			width:$right-col-width;
		}
	}


	.divider-line-container {
		position: relative;
		z-index: 5000;

		.divider-line {
			position: relative;
			z-index: 6000;

			.triangle {
				content:"";
				position:absolute;
				top:80%;
				right:0;
				width:110%;
				height:30px;
				background:$white;
				z-index: 6000;
				@include transform-origin(100% 100%);
				@include transform(rotate(-1deg));

				@include mq($from: smallphone) {
					top:50%;
					height:20px;
				}

				@include mq($from: smallphone) {
					top:45%;
					height:25px;
				}

				@include mq($from: desktop) {
					top:25%;
					height:35px;
				}

				@include mq($from: largedesktop) {
					top:17%;
					height:35px;
				}
			}

			h1,h2,h3 {
				position: relative;
				margin:0;
				padding:20px 10px 20px;
				z-index: 6000;
			}
		}
	}



	.breadcrumb-page-level-social-container {
		position: relative;
		@include clearfix();

		.page-level-socialIcons-container {
			@include mq($from: tablet) {
				position:absolute;
				top:4px;
				right:0;
			}
		}

	}

	.footer-page-level-social-container {
		position: relative;
		@include clearfix();

		> p {
			padding:0;
			text-align:center;

			@include mq($from: tablet) {
				float:left;
				padding:20px 0;
			}
		}
	}