/*--------------*/
/*	VIDEO EMBED */
/*--------------*/

	.video-embed {
		position: relative;
		
		.video-embed__thumbnail {
			position: relative;
			z-index: 2;
			cursor: pointer;
			
			img {
				width: 100%;
				height: auto;
				vertical-align: top;
			}

			a.play-icon {
				width:72px;
				height:53px;
				background-color:$black;
				border-radius:5px;
				@include centerer();

				// @include icomoon(arrow-play, after){
				// 	color:white;
				// 	@include font-size(3);
				// 	position: absolute;
				// 	top: 52%;
		  //           left: 47%;
	   //          	@include transform(translate(-50%, -50%));
				// }

				&:hover,
				&:focus {
					background-color:$black;
				}
			}
		}
		
		iframe {
			display: none;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			vertical-align: top;
		}
	}


	.embed-container { 
		position: relative; 
		margin-bottom:40px;
		padding-bottom: 56.25%; 
		height: 0; overflow: 
		hidden; 
		max-width: 100%;
	} 

	.embed-container iframe, 
	.embed-container object, 
	.embed-container embed { 
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; }


	