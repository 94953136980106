/*-------*/
/* ICONS */
/*-------*/

	.scrollDownIcon {

		&:hover,
		&:focus {
			text-decoration: none;

			&:after {
				color:$yellow;				
			}
		}

		@include icomoon(arrow-down-circle, after, true)  {
			@include font-size(3, 0);
			color:$white;
		}
	}

	



	.social-icons {

		margin:0;
		padding:0;
		list-style: none;

		li {
			// border:1px solid red; 
			display: inline-block;

			a {
				display: block;
				// border:1px solid cyan;
				position: relative;
				padding:20px 30px;
				@include transition($fast-anim);
				color:$white;

				&:hover {
					text-decoration: none;
					color:$yellow;
				}
			}
		}
		
		.facebook {
			@include icomoon(facebook-circle, after, true)  {
				@include centerer();
				@include font-size(3, 0);
				// color:$white;
			}
		}

		.twitter {
			@include icomoon(twitter-circle, after, true)  {
				@include centerer();
				@include font-size(3, 0);
				// color:$white;
			}
		}

		.pinterest {
			@include icomoon(pinterest, after, true)  {
				@include centerer();
				@include font-size(3, 0);
				// color:$white;
			}
		}

		.instagram {
			@include icomoon(instagram-circle, after, true)  {
				@include centerer();
				@include font-size(3, 0);
				// color:$white;
			}
		}

		.youtube {
			@include icomoon(youtube-circle, after, true)  {
				@include centerer();
				@include font-size(3, 0);
				// color:$white;
			}
		}		
		.printer {
			@include icomoon(printer, after, true)  {
				@include centerer();
				@include font-size(3, 0);
				// color:$white;
			}
		}
	}

	.social-icons.social-icons-colour {
		li {
			a{
				&.facebook {
					color:#3b5998;
				}

				&.twitter {
					color:#3bafe9;
				}

				&.pinterest {
					color:#c9161d;
				}

				&.instagram {
					color:$grey;
					display:none
				}

				&.youtube {
					color:$grey;
				}

				&.printer {
					color:$grey;
				}

				&:hover {
					color:$black;
				}




			}
		}
	}




	.page-level-socialIcons-container {
		text-align: center;

		p {
			display: inline-block;
			vertical-align: middle;
			margin:0;
			padding:0;
			//IE10/11 fix
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
				margin-top:10px;
			}
		}
		.social-icons {
			display: inline-block;
			vertical-align: middle;
			margin-left:10px;
			li {
				a{
					padding:20px 20px;

					&.instagram {
						display:none
					}

					&.youtube {
						display:none
					}
				}
			}
			//IE10/11 fix
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
				margin-top:10px;
			}
		}

	}


	.icon {
		display: block;
	}

	.icon-birds {
		@include font-size(3);
	}
	.icon-HER {
		@include font-size(3);
	}
	.icon-heritage-trails {
		@include font-size(3);
	}
	.icon-Historic-building {
		@include font-size(3);
	}
	.icon-industrial {
		@include font-size(3);
	}
	.icon-insects {
		@include font-size(3);
	}
	.icon-Iron-age {
		@include font-size(3);
	}
	.icon-Mammals {
		@include font-size(3);
	}
	.icon-medieval {
		@include font-size(3);
	}
	.icon-military {
		@include font-size(3);
	}
	.icon-Other-animals {
		@include font-size(3);
	}
	.icon-Plants {
		@include font-size(3);
	}
	.icon-Ponies {
		@include font-size(3);
	}
	.icon-prehistory {
		@include font-size(3.5);
	}
	.icon-Victorian {
		@include font-size(3);
	}