/*--------*/
/* HEADER */
/*--------*/

	.header-container {
		// border:3px dashed red;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		z-index: 2000;

		.logo-tools {
		
			position: relative;
			margin-top:20px;
			@include clearfix();

			.logo {
				float:left;
				@include transition(all, $fast-anim)

				&:hover,
				&:focus {
					@include transform(scale(1.1))
				}

				img {
					display:block;
					width:70%;
					height:auto;

					@include mq($from: largephone) {
					    width: 100%;
					}
				}
			}

			.menu-search-container {

				background-color: rgba(69, 85, 95, 0.5);
  				border-radius: 2px;
  				padding-left: 10px;
				
				// border:5px solid red;
				@include centerer(50%, 50%, false, true)
				right:0;
				@include clearfix();
				

				.menu-icon {
					// border:5px solid lime;
					padding:10px 0px;
					
					float:left;
					display:block;

					width:110px;
					position: relative;
					
					color:$white; 
					
					@include font-size(1.8);
					text-transform: uppercase;

					@include icomoon(menu2, after)  {
						@include font-size(5, 0);
						color:$yellow;
						position:absolute;
						top:53%;
						right:0px;
					}

					&:hover,
					&:focus {
						
						color:$yellow;
						text-decoration:none;

						&:after {
							color:$yellow;
						}
					}

					&.active {
						@include icomoon(cross, after)  {
							color:$yellow;
							@include font-size(3.8, 0);						
						}
					}

				}//menu-icon

				

				.search-icon {
					// border:5px solid yellow;
					padding:10px 5px 10px 10px;

					display:inline-block;

					height:100%;
					
					@include font-size(1.5);
					text-transform: uppercase;

					@include icomoon(mag-glass, after, true) {
						position: relative;
						top:12px;
						@include font-size(3, 0);
						color:$white;
					}

					&:hover,
					&:focus {
						
						text-decoration:none;

						&:after {
							color:$yellow;	
						}
					}

					&.active {
						@include icomoon(cross, after)  {
							color:$yellow;
							@include font-size(3.8, 0);						
						}
					}

				}//search-icon

					.home-icon {
					// border:5px solid yellow;
					padding:10px 10px 10px 5px;

					float:right;
					display:inline-block;

					height:100%;
					
					@include font-size(1.5);
					text-transform: uppercase;

					@include icomoon(home3, after, true) {
						position: relative;
						top:12px;
						@include font-size(3, 0);
						color:$white;
					}

					&:hover,
					&:focus {
						
						text-decoration:none;

						&:after {
							color:$yellow;	
						}
					}

					&.active {
						@include icomoon(cross, after)  {
							color:$yellow;
							@include font-size(3.8, 0);						
						}
					}

				}//home-icon

			}//menu-search-container

		}//logo tools
	}



	.searchbox-container {
		
		// border:3px solid red;

		padding:150px 0 80px;
		background-color: rgba($grey, 0.7);

		display:none;
		position: absolute;
		top:0px;
		left:0;
		width:100%;
		z-index: 1500;
		text-align: center;

		&.active {
			display:block;
		}

		form {
			position: relative;
			margin:0 auto;
			width:95%;
			@include mq($from: smalldesktop) {
				width:40%;
			}
		}

		input {
			width:100%; 
			border:0;
			border-radius:0;
			padding:15px;
			padding-right:50px;
			@include font-size(1.8, 3);
			border-bottom:5px solid $grey;

			@include mq($from: smalldesktop) {
				max-width:$page-width;
				padding:15px 20px;
				padding-right:50px;
				@include font-size(2,3.2);
			}
			@include placeholder {
			    padding-top:5px;
			    font-style:italic;
			    color: $lightgrey;
			}
			&:focus {
				outline:0;
			}
		}



		.search-icon {

			position: absolute;
			top:0%;
			right:0px;
			height:100%;
			background: none;
			z-index: 1000;
			padding:1px 10px;

			@include icomoon(mag-glass, after, true) {
				position: relative;
				font-size:30px;
				line-height:40px;
				color:$grey;
			}

			&:focus,
			&:hover {
				background-color:$grey;

				&:after {
					color:$white;	
				}
			}
		}
	}