/*------*/
/*	MAP */
/*------*/

	#map {
		height: 450px;
		margin-bottom:60px;
		border:1px solid $grey;
	}

	#selectBox {
		font-size: large;
		height: 30px;
		width: 170px;
	}