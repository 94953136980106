/*------------------------------------------------------------------------------------------------------------*/
/*  MIXINS                                                                                                    */
/*------------------------------------------------------------------------------------------------------------*/  

	@mixin linear-gradient($fromColor, $toColor) {
		background-color: $toColor; /* Fallback Color */
		background-image: -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor)); /* Saf4+, Chrome */
		background-image: -webkit-linear-gradient(top, $fromColor, $toColor); /* Chrome 10+, Saf5.1+, iOS 5+ */
		background-image:    -moz-linear-gradient(top, $fromColor, $toColor); /* FF3.6 */
		background-image:     -ms-linear-gradient(top, $fromColor, $toColor); /* IE10 */
		background-image:      -o-linear-gradient(top, $fromColor, $toColor); /* Opera 11.10+ */
		background-image:         linear-gradient(top, $fromColor, $toColor);
		filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
	}

	
	@mixin transition($transition...) {
		-webkit-transition: $transition;
		-moz-transition: $transition;
		-ms-transition: $transition;
		-o-transition: $transition;
		transition: $transition;
	}

	
	@mixin transform($transforms) {
		-webkit-transform: $transforms;
		-moz-transform: $transforms;
		-ms-transform: $transforms;
		-o-transform: $transforms;
		transform: $transforms;
	}
	
	@mixin transform-origin($transform-origin...) {
        -webkit-transform-origin: $transform-origin; 
        -moz-transform-origin: $transform-origin; 
          -ms-transform-origin: $transform-origin; 
              transform-origin: $transform-origin; 
    }


	/*  Usage:
			@include placeholder {
			    font-style:italic;
			    color: white;
			    font-weight:100;
			}
	*/
	@mixin placeholder {
		&::-webkit-input-placeholder {@content}
		&:-moz-placeholder           {@content}
		&::-moz-placeholder          {@content}
		&:-ms-input-placeholder      {@content}  
	}





	@mixin border-radius($radius) {
		-webkit-border-radius: $radius+px;
		border-radius: $radius+px;
		background-clip: padding-box;  /* stops bg color from leaking outside the border: */
	}


	@mixin list-cols($cols, $gap){
		list-style-position: inside;
		-moz-column-count:$cols; 
		-moz-column-gap:$gap + em; 

		-webkit-column-count:$cols;
		-webkit-column-gap:$gap + em;
	}



	/* Usage: @include opacity(0.8); */
	@mixin opacity($opacity) {
		opacity: $opacity;
		$opacity-ie: $opacity * 100;
		filter: alpha(opacity=$opacity-ie); //IE8
	}



	@mixin box-shadow($box-shadow...) {
			-webkit-box-shadow: $box-shadow;
			-moz-box-shadow: $box-shadow;
			-ms-box-shadow: $box-shadow;
			-o-box-shadow: $box-shadow;
			box-shadow: $box-shadow;
	}



	@mixin text-shadow($text-shadow...) {
			-webkit-text-shadow: $text-shadow;
			-moz-text-shadow: $text-shadow;
			-ms-text-shadow: $text-shadow;
			-o-text-shadow: $text-shadow;
			text-shadow: $text-shadow;
	}



	@mixin border-box() {
		-webkit-box-sizing: border-box; 
		-moz-box-sizing: border-box; 
		-o-box-sizing: border-box; 
		box-sizing: border-box;	
	}


	
	/* Usage: @include font-size(2.2); */
	@mixin font-size($sizeValue: 1.6, $line-height:$sizeValue*1.3) {
		font-size: ($sizeValue * 10) + px;
		font-size: $sizeValue + rem;
		
		line-height: ($line-height * 10) + px;
		line-height:$line-height+rem;
	}


	@mixin line-height($line-height) {
		
		line-height: ($line-height * 10) + px;
		line-height:$line-height+rem;
	}



	@mixin clearfix {
		&:after {
			content: "";
			display: table;
			clear: both;
		}
	}


	@mixin centerer($top: 50%, $left: 50%, $horizontal: true, $vertical: true) {
        position: absolute;
        @if ($horizontal and $vertical) {
            top: $top;
            left: $left;
            @include transform(translate(-50%, -50%));
        } @else if ($horizontal) {
            left: $left;
            @include transform(translate(-50%, 0));
        } @else if ($vertical) {
            top: $top;
            @include transform(translate(0, -50%));
        }
    }


	/* Usage:
		//a 3rd parameter of true will hide the text
		@include icomoon(clock, before)
    	{
    		//can set extra CSS here
    		margin-right:5px;
    	}
	*/

	@mixin icomoon($icon, $position: "before", $replace: false) {
        // If we're replacing the text, set font-size to 0
        @if $replace {
            font-size: 0;
        }
        // Pseudo-element properties
        &:#{$position} {
            @extend .icon-#{$icon}:before;
            font-family: 'icomoon';
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            @if $replace {
              font-size: 1rem;
            }
            @content;
        }
    }



	/* css triangles */

	/*  Usage:
			@include arrow('top','right', 8px, #fff, #ccc, 1px);
			@include arrow(top|bottom|left|right, left|center|right, arrow-size, arrow-color, arrow-border-color, arrow-border-size);
	*/
	@mixin arrow($side: "right", $align: "center", $size: 20px, $color: #f6f6f6, $borderColor: "none", $borderSize: 3px) {
		$selector: "&:after, &:before";
		@if $borderColor == "none" {
			$selector: "&:after";
		}
		#{$selector} {
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			visibility: visible;
		}
		
		&:after {
			border-width: $size;
			@include arrow_helper($size, $color, $size, $side, $align);
		}
		
		@if $borderColor != "none" {
			&:before {
				border-width: $borderSize + $size;
				@include arrow_helper($size + $borderSize, $borderColor, $size - $borderSize, $side, $align);
			}
		}
	}

	

	/* called from @mixin arrow */
	@mixin arrow_helper($arrowSize, $arrowColor, $margin, $side, $align) {
		
		@if $side == "top" {
			border-bottom-color: $arrowColor;
			top: -2 * $arrowSize;
		}
		
		@if $side == "bottom" {
			border-top-color: $arrowColor;
			bottom: -2 * $arrowSize;
		}
		
		@if $side == "left" {
			border-right-color: $arrowColor;
			left: -2 * $arrowSize;
		}
		
		@if $side == "right" {
			border-left-color: $arrowColor;
			right: -2 * $arrowSize;
		}
		
		@if $side == "right" or $side == "left" {
			@if $align == "center" {
				top: 50%;
				margin-top: -$arrowSize;
			}
			@else if $align == "top" {
				top: $margin;
			}
			@else if $align == "bottom" {
				bottom: $margin;
			}
		}

		@if $side == "bottom" or $side == "top" {
			@if $align == "center" {
				right: 50%;
				margin-right: -$arrowSize;
			}
			@else if $align == "left" {
				left: $margin;
			}
			@else if $align == "right" {
				right: $margin;
			}
		}
	}