
@font-face {
    font-family: 'icomoon';
    src:url($path-to-icomoon+'icomoon.eot?5avz9i');
    src:url($path-to-icomoon+'icomoon.eot?5avz9i#iefix') format('embedded-opentype'),
        url($path-to-icomoon+'icomoon.ttf?5avz9i') format('truetype'),
        url($path-to-icomoon+'icomoon.woff?5avz9i') format('woff'),
        url($path-to-icomoon+'icomoon.svg?5avz9i#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before,
[class^="icon-"]:after, [class*=" icon-"]:after {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-menu-bars:before {
    content: "\e609";
}
.icon-cross:before {
    content: "\e608";
}
.icon-external-link:before {
    content: "\e800";
}
.icon-location-pin:before {
    content: "\e604";
}
.icon-mag-glass:before {
    content: "\e605";
}
.icon-question-mark:before {
    content: "\e606";
}
.icon-printer:before {
    content: "\e60a";
}
.icon-arrow-left:before {
    content: "\e601";
}
.icon-arrow-right:before { 
    content: "\e602";
}
.icon-arrow-up:before {
    content: "\e603";
}
.icon-arrow-down:before {
    content: "\e600";
}
.icon-arrow-down-circle:before {
    content: "\e903";
}
.icon-arrow-up-circle:before {
    content: "\e904";
}
.icon-arrow-left-circle:before {
    content: "\e905";
}
.icon-arrow-right-circle:before {
    content: "\e906";
}
.icon-arrow-right2:before {
    content: "\e801";
}
.icon-play-arrow:before {
    content: "\e60b";
}
.icon-play-button:before {
    content: "\e900";
}
.icon-play-button-circle:before {
    content: "\e907";
}
.icon-facebook-circle:before {
    content: "\e908";
}
.icon-instagram-circle:before {
    content: "\e90a";
}
.icon-twitter-circle:before {
    content: "\e90b";
}
.icon-youtube-circle:before {
    content: "\e90d";
}
.icon-googleplus:before {
    content: "\e901";
}
.icon-facebook:before {
    content: "\e902";
}
.icon-twitter:before {
    content: "\e607";
}
.icon-plus:before {
    content: "\e909";
}
.icon-minus:before {
    content: "\e90c";
}
.icon-pinterest:before {
    content: "\e90e";
}
.icon-menu4:before {
    content: "\e90f";
}
.icon-menu3:before {
    content: "\e910";
}
.icon-menu2:before {
    content: "\e911";
}
.icon-menu1:before {
    content: "\e912";
}
.icon-birds:before {
    content: "\e913";
}
.icon-HER:before {
    content: "\e914";
}
.icon-heritage-trails:before {
    content: "\e915";
}
.icon-Historic-building:before {
    content: "\e916";
}
.icon-industrial:before {
    content: "\e917";
}
.icon-insects:before {
    content: "\e918";
}
.icon-Iron-age:before {
    content: "\e919";
}
.icon-Mammals:before {
    content: "\e91a";
}
.icon-medieval:before {
    content: "\e91b";
}
.icon-military:before {
    content: "\e91c";
}
.icon-Other-animals:before {
    content: "\e91d";
}
.icon-Plants:before {
    content: "\e91e";
}
.icon-Ponies:before {
    content: "\e91f";
}
.icon-prehistory:before {
    content: "\e920";
}
.icon-Victorian:before {
    content: "\e921";
}
.icon-home3:before {
    content: "\e922";
}

