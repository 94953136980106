/*---------*/
/*	TABLES */
/*---------*/

table, th, td {
	border:1px solid $teal;
	border-collapse:collapse;
}
table {
	margin:40px auto;
}
th, td {
	padding:15px 10px;
}

.teal-table {
	th {
		background-color: $teal;
		color:white;
		font-weight:$light;
		text-align: left;
	}
tr:nth-child(odd) {color:black;background-color: white;}
tr:nth-child(even) {color:black;background-color: rgba(0, 127, 153, 0.5);}
}


@media only screen and (max-width : 480px) {

	table.teal-table {
	width: 80%;
	font-size: 14px;
	}
}