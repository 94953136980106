/*---------*/
/*	HELPER */
/*---------*/

	.clearfix {
		@include clearfix;
	}

	/* LEFT */
	.img-float-left {
		float:left;
		margin:0 40px 40px 0
	}
		
	.float-left {
		float:left;
	}

	.text-left {
		text-align:left!important
	}

	.text-left-at-tablet {
		@include mq($from: tablet) {
	        text-align:left!important
	    }
	}
	
	.text-left-at-desktop {
		@include mq($from: desktop) {
	        text-align:left!important
	    }
	}
	
	

	/* RIGHT */
	.img-float-right {
		float:right;
		margin:0 0 40px 40px
	}



	.float-right {
		float:right
	}

	.text-right	{
		text-align:right!important
	}
	
	.text-right-at-tablet {
		@include mq($from: tablet) {
	        text-align:right!important
	    }
	}
	
	.text-right-at-desktop {
		@include mq($from: desktop) {
	        text-align:right!important
	    }
	}


	/* FLOAT NONE */
	.float-at-smallphone {
		@include mq($until: smallphone) {
			float:none;
		}
	}

	.float-at-largephone {
		@include mq($until: largephone) {
			float:none;
		}
	}

	.float-at-tablet {
		@include mq($until: tablet) {
			float:none;
		}
	}




	/* CENTER */
	.center,
	.text-center {
		text-align:center!important;
		margin-left:auto;
		margin-right:auto;
	}
		
	
	.off-screen {
		position:absolute;
		top:-9999px;
		left:-9999px
	}
	
	.hidden	{
		display: none!important;
		visibility: hidden;
	}

	.margin-bottom-30 {
		margin-bottom:30px;
	}
	
	.short-date	{
		margin:0.4em 0;
		color:#666
	}
	
	.full-date {
		margin:0.4em 0;
		color:#666;
		font-style:italic
	}
	
	
	//Top
	.backPos-topLeft {
		background-position:0% 0%;
	}
	.backPos-topCenter {
		background-position:50% 0%;
	}
	.backPos-topRight {
		background-position:100% 0%;
	}

	//Center
	.backPos-centerLeft {
		background-position:0% 50%;
	}
	.backPos-centerCenter {
		background-position:50% 50%;
	}
	.backPos-centerRight {
		background-position:100% 50%;
	}

	//Bottom
	.backPos-bottomLeft {
		background-position:0% 100%;
	}
	.backPos-bottomCenter {
		background-position:50% 100%;
	}
	.backPos-bottomRight {
		background-position:100% 100%;
	}


	.background-cover {
		background-repeat:no-repeat; 
		-webkit-background-size:cover;
		-moz-background-size:cover;
		-o-background-size:cover;
		background-size:cover;
	}

	.background-contain {
		background-repeat:no-repeat; 
		-webkit-background-size:contain;
		-moz-background-size:contain;
		-o-background-size:contain;
		background-size:contain;
	}

	//768px
	.show-at-tablet {		
		display:none;
		
		@include mq($from: tablet) {
	        display:block;
	    }
	}

	.hide-at-tablet {		
		display:block;

		@include mq($from: tablet) {
	        display:none;
	    }
	}


	//960px
	.show-at-small-desktop {		
		display:none;
		@include mq($from: smalldesktop) {
	        display:block;
	    }
	}

	.hide-at-small-desktop {		
		display:block;

		@include mq($from: smalldesktop) {
	        display:none;
	    }
	}


	//1200px
	.show-at-desktop {		
		display:none;
		@include mq($from: desktop) {
	        display:block;
	    }
	}

	.hide-at-desktop {		
		display:block;
		@include mq($from: desktop) {
	        display: none;
	    }
	}

	
	//Full Size Until
	.full-size-until-tablet {
		@include mq($until: tablet) {
			width: 100%;
			margin: 0;
		}
	}

	.full-size-until-small-desktop {
	    @include mq($until: smalldesktop) {
			width: 100%;
			margin: 0; 
		}
	}

	.full-size-until-desktop {
	    @include mq($until: desktop) {
			width: 100%;
			margin: 0; 
		}
	}



	//Responsive Until
	.responsive-until-tablet {
		@include mq($until: tablet) {
			width: 100%;
			height:auto;
		}
	}

	.responsive-until-smalldesktop {
		@include mq($until: smalldesktop) {
			width: 100%;
			height:auto;
		}
	}

	.responsive-until-desktop {
		@include mq($until: desktop) {
			width: 100%;
			height:auto;
		}
	}



	/* Background / Foreground Combos */
	.dark-background {
		h1, h2, h3, 
		h4, p {
			color:$white;
		}
	}

	.light-background {
		h1, h2, h3, 
		h4, p {
			color:$grey;
		}
	}

	.light-background-at-largephone {
		@include mq($from: largephone) {
			h1, h2, h3, 
			h4, p {
				color:$grey;
			}
		}
	}

	.light-background-at-smalldesktop {
		@include mq($from: smalldesktop) {
			h1, h2, h3, 
			h4, p {
				color:$grey;
			}
		}
	}




	/* General */
	.no-scroll {
		overflow:hidden;
		-ms-touch-action:none;
	}
	
	.responsive-img,
	.responsive-image,
	.image-full-width,		
	.full-width	{
		display:block; 
		width:100%;
		height:auto;
	}
	
	

	/* JS HIDE */
	.js .js-hide {
		display:none
	}
	.no-js .js-show {
		display:none;
	}
	
	.no-js .js-hide {
		display:block
	}
	
	.no-js .lazyload {
		display:none!important;
	}


	//Page heights
	.halfPageHeight {

		@include mq($from: tablet) {
			height: 45vh
		}		
	}


	.quarterPageHeight {

		@include mq($from: tablet) {
			height: 35vh
		}		
	}