/*-------*/
/* FONTS */
/*-------*/

@font-face {
    font-family: 'riffic_freebold';
    src: url($path-to-fonts + '/Riffic/rifficfree-bold-webfont.eot');
    src: url($path-to-fonts + '/Riffic/rifficfree-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url($path-to-fonts + '/Riffic/rifficfree-bold-webfont.woff2') format('woff2'),
         url($path-to-fonts + '/Riffic/rifficfree-bold-webfont.woff') format('woff'),
         url($path-to-fonts + '/Riffic/rifficfree-bold-webfont.ttf') format('truetype'),
         url($path-to-fonts + '/Riffic/rifficfree-bold-webfont.svg#riffic_freebold') format('svg');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'texgyreadventorregular';
    src: url($path-to-fonts + '/tex-gyre-adventor-regular/texgyreadventor-regular-webfont.eot');
    src: url($path-to-fonts + '/tex-gyre-adventor-regular/texgyreadventor-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url($path-to-fonts + '/tex-gyre-adventor-regular/texgyreadventor-regular-webfont.woff') format('woff'),
         url($path-to-fonts + '/tex-gyre-adventor-regular/texgyreadventor-regular-webfont.ttf') format('truetype'),
         url($path-to-fonts + '/tex-gyre-adventor-regular/texgyreadventor-regular-webfont.svg#texgyreadventorregular') format('svg');
    font-weight: normal;
    font-style: normal;

}