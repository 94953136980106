/*----------------------------------------------------------------------------------------------------------*/
/*	THIRD PARTY IFRAMES													  						  	  	  	*/
/*----------------------------------------------------------------------------------------------------------*/

#wamframe {

	display:block;
	width:100%;
	height:500px;

	@include mq($until: largephone) {
		height:900px;
	}
}


.google-maps {
    position: relative;
    margin-bottom:40px;
    padding-bottom: 52.5%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
}
.google-maps iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}