/*--------*/
/* LISTS  */
/*--------*/

	.cta-container {
		@include clearfix;
		padding-left: 0;
		li {
			@include clearfix;
			margin-bottom:30px;
			padding:0;
			list-style: none;
			
			.image-block {
				display: none;
				width:120px;
				float:left;
				margin-right:20px;
				text-align: center;
				
				@include mq($from: largephone) {
					display: block;
				}
				
				img {
					max-width:100%;
					height:auto;
				}
			}
			
			.text-block {
				overflow:hidden;
				padding-bottom:3px;

				p {
					padding-bottom:0;
				}

				.organisation-email,
				.organisation-website,
				.organisation-phone-number {
					font-weight:700;
					padding-bottom: 0.5em;

					a {
						font-weight:500;
					}
				}

				a {
					

					&.more {
						position: relative;
						display:inline-block;
						padding-right:20px;
						color:$black;
						font-weight:$regular;
						
						@include icomoon(arrow-right, after) {
							position: absolute;
							top:-2px;
							right:0;
							margin-left:5px;
							margin-top:4px;
						} 
					}

				}
			}
		}
	} 


	ul.tags {
		margin:5px 0 0;
		padding:0;
		list-style: none;
		
		li {
			margin:0 8px 8px 0;
			padding:0;
			display: inline-block;
			
			a {
				display: inline-block;
				color:$grey;  
				@include font-size(1.4);
				font-weight:$regular;
				padding:5px 10px;
				background-color: $grey;
				border:1px solid $black;
				border-radius: $rad/5;
			}
		}
	}



	.no-bullets {			
		margin:0;
		padding:0;
		list-style:none; 
		
		li {
			margin:0;
			padding:0;
			list-style:none; 
		}
	}


	.arrows-after {
		li {
			a {
				display:inline-block;
				padding:10px 0;
				color:$teal;
				@include icomoon(arrow-right, after)
				{
					@include font-size(1.2);
					margin-left:5px;
				}
			}
		}
	}
