/*-------------*/
/*	PAGINATION */
/*-------------*/

	/* Pagination Filter Container */
		.pagination-filter-container {
			
			text-align:center; 
			margin-bottom: $normal-padd;
			

			//
			// < 1 | 2 | 3 | 4 >
			//
			.pagination {
				margin:0;
				padding:0;
				list-style-type: none;
							
				li {
					vertical-align: top; 
					position: relative;
					display:inline-block;
					margin:0px;
					padding:0;
					
					a {
						display:inline-block;
						width:30px;
						height:30px; 
						padding:5px;
						vertical-align:top;
						color:$black;
						border-radius: 3px;


						//Prev / Next arrows
						&.prev,
						&.next {
							background-color: $grey;
							padding:5px 15px;
							&:before {
								color:$black;
								@include centerer();
							}
						}

						&.prev {
							@include icomoon(arrow-left, before, true);
						}
						&.next {
							@include icomoon(arrow-right, before, true);
						}

						&:focus,
						&:hover,
						&.active {
							background-color: $black;
							color:$white!important;
							text-decoration: none;
						}					
					}
					
					&:first-child a,
					&:nth-last-child(-n+2) a {					
						border:0;
					}
				}
			}		
		}