/*----------------------------------------------------------------------------------------------------------*/
/*	ACCORDION																	  						  	*/
/*----------------------------------------------------------------------------------------------------------*/
		
	/* Accordion */
		.accordion {
			position:relative;
			margin:0 0 30px;
			padding:0;
			max-height:1000000px;

			.accordionPanel {

				margin-bottom:2px;

				&.active {
					.titleHeader {

					 	@include icomoon(arrow-up, after)  {
							position:absolute;
							top:22%;
							right:10px;
						}
					}
				}

				.titleHeader {
					position:relative;					
					cursor:pointer;
					background-color:$black;
					color:$black;
					 
					 @include icomoon(arrow-down, after)  {
						position:absolute;
						top:25%;
						right:10px;
					}


					a {
						display:block;
						margin:0;
						padding:10px 35px 10px 10px;
						&:hover,
						&:focus {
							background-color:darken($black, 7%);
							text-decoration: none;
						}
						color:$black;
					}
				}

				.contentDetails {
					@include clearfix;
					
					display:none;
					margin:0 0 3px;
					// padding:0;
					background:white;
					padding: 10px 5px;

					p {
						&:last-child {
							padding-bottom:0	
						}
					}

					.cta-container {
						
						margin:10px 0;
						@include mq($from: largephone) {
							padding:0 20px;
						}
						
						li {
							margin-bottom:15px;
							padding-bottom:15px;
							border-bottom:1px solid $black;
							
							&:last-child {
								margin:0;
								border:0;
							}

							.text-block {
								padding:0 10px;
								
								a {
									display:block;

									&.more {
										font-weight:$regular;
									}
								}								
							}
						}
					}
				}
			}
		}