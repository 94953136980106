/*------------*/
/* MOBILE NAV */
/*------------*/

	.mobileNav {
			
		// display: block;

		// @include mq($from: desktop) {
		// 	display: none;
		// }

		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		z-index: 500;
		@include transition(transform $fast-anim);
		overflow:hidden;
		background-color: $grey;
		padding: 120px 0 30px;

		@include transform(translate3d(100%, 0, 0));  //position it offscreen
		
		&.active {
			@include transform(translate3d(0, 0, 0) !important);  //move into view when it's active
		}
		
		// lt-ie9 override - don't ever show
		.ie9 &,
		.lt-ie9 & {
			@include transform(none !important);
			display: none;
		}




		//li's that have children generic
		.has-children {
			
			// apply to direct nav name
			> .nav-name {
				position: relative;
				
				a {
					color: $yellow;
				}
				
				@include icomoon(arrow-down, after)
				{
					position: absolute;
					top: 50%;
					right: 10px;
					margin-top: -13px;
					@include font-size(1.8);
					cursor: pointer;
					color: $yellow;
				}
			}			
			
			// when open load arrow up
			&.active > .nav-name {
				
				@include icomoon(arrow-up, after);
			}
		
		} //has-children



		// indent child ul's for side & mobile nav
		.subnav-contents {
			
			display: none;
			margin-left: 15px;

			a {
				color:white;
			}

			.has-children {
				a {
					color:white;
				}
			}

		} //subnav-contents

	} //mobileNav