/*-------------*/
/*	NAVIGATION */
/*-------------*/

.left-menu-container {

	h2 {
		margin:0;
		padding:0;
		color:$white;

		a {
			color:$white;
			@include font-size(1.9); 
			display:block;
			text-align:center;
			padding:8px 0
		}
	}

	//Show the the 1st level nav and its 1st level children
	#left-menu  {

		position: relative;
		border:1px solid $green;
		border:0px;	
		
		a {
			@include font-size(1.45);
			display: block;
			position: relative;
			padding:15px 10px 15px 50px;
			color:$green;
			font-weight: $bold;
		}

		//hide all the sub navs, we want to control their appearance
		.subnav-contents {

			.subnav-contents {
				display:none;

				//3rd level
				.nav-name {
					padding-left:25px;

					&:before {
						top:7px;
						left:35px;
					}
					
					a {
						color:$black;
						font-weight: $regular;
						padding-top:12px;
						padding-bottom:12px;
					}
				}

				.subnav-contents {

					//4th level
					.nav-name {
						padding-left:45px;

						&:before {
							left:55px;
						}
					}

					.subnav-contents {

						//5th level
						.nav-name {
							padding-left:60px;

							&:before {
								left:70px;
							}
						}
					}
				}
			}
		}

		
		> ul {  //very top level UL

			// border:1px solid lime;
			
			> li {
				
				// border:1px solid red;
				border-bottom:1px solid #ccc;


				> .nav-name {
					
					

					a {
						
						@include font-size(1.7);

						&:focus,
						&:hover {
							color:$grey;
						}
					}
				}
			}
		}

		.current {
			background-color: $green;

			&:before {
				//border:1px solid $white;
				color:$white;
				font-weight: bold;
			}
			a {
				color:$white!important;
				font-weight:$bold;
			}
		}
	}

	

	//all LI's that have children
	.has-children {
		
		// apply to direct nav name
		> .nav-name {
			position: relative;
			overflow:hidden;
			
			//Show +/- at desktop
			@include icomoon(plus, before)
			{
				//border:1px solid $green;
				//border-radius:3px;
				position: absolute;
				top:12px;
				left:10px;
				width:25px;
				height:25px;
				text-align:center;
				font-weight: bold;
				z-index:1000;
				font-size:25px;
				line-height:25px;
				cursor: pointer;
				color: $green;
			}
		}

		
		// when sub nav is expanded
		&.active > .nav-name {
				
			@include icomoon(minus, before)
			{
				font-size:25px;
				line-height:25px;
			}

		}
	
	} //has-children



}
