/*---------*/
/* FUNZONE */
/*---------*/

.ranger-ralph-cta {
	@include clearfix();
	position: relative;
	padding:20px 0;
	text-align: center;	

	p {
		font-family:$funzone-font;
		@include font-size(2.8);
		margin:10px 0 20px;
		padding:0;

		@include mq($from: smalldesktop) {
			float:left; 
			width:75%;
			margin:10px 0;
		}
	}

	.button {
		font-family:$funzone-font;
		@include font-size(2.5);
		color:$red;
		padding:10px 30px;
		display:block;
		margin:0 auto;

		@include mq($from: largephone) {
			display:inline-block;
		}

		@include mq($from: smalldesktop) {
			float:right;
		}
	}
}

