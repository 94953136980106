.breadcrumbs {
	padding: 20px 0;
	@include clearfix();

	
	@include mq($from: tablet) {
		padding-left: 0;
		padding-right: 0;
		width:50%;
	}

	@include mq($from: smalldesktop) {
		width:70%;
	}

	ul {
		float:left;
	}

	li, 
	span[typeof="v:Breadcrumb"] {
		vertical-align: middle;
		display: inline-block;
		
		a,
		&:before,
		span {
			vertical-align: middle;
			display: inline-block;
			color: $text-grey;
			@include font-size(1.5);
		}
		a,
		span {
			padding: 5px 0;
		}
		
		@include icomoon('arrow-right') {
			@include font-size(1);
			line-height: 1;
			margin: 0 8px;
		}
		
		&:first-child:before {
			content: '';
			display: none;
		}
	}
}




