/*-------------*/
/*	PAGE TOOLS */
/*-------------*/

.dartmoor-attraction-filter {
	width:100%;
	margin:0 auto;
	padding:0;
	list-style:none;

	li {
		padding-right:30px;
		overflow:hidden;
		position: relative;

		&:first-child {
			a {
				border-top:1px solid $grey;
			}
		}

		a {
			display:block;
			padding:10px 10px;
			border:1px solid $grey;
			border-top:0;
			color:$grey;

			&:after {
				display: none;
				content:"";
				position: absolute;
				top: 0;
				right:17px;
				width:40px;
				height:40px;
				z-index: 1;
				@include transform-origin(50% 50%);
				@include transform(rotate(45deg));
			}

			&:hover,
			&:focus,
			&.active {
				background-color: $grey;
				color:$white;

				&:after {
					display:block;
					background-color: $grey;
				}
			}
		}
	}
}