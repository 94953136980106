/*----------------------------------------------------------------------------------------------------------*/
/*	FORMS																  						  	  	  	*/
/*----------------------------------------------------------------------------------------------------------*/


	input[type="text"],
	textarea {
		border-radius:0;
		-webkit-appearance: none;
		border:1px solid $grey;
	}


	fieldset {


		&.quarter {
			width:100%;

			@include mq($from: tablet) {
				width:25%;
			}
		}

		&.half {
			width:100%;

			@include mq($from: tablet) {
				width:50%;
			}
		}

		&.threeQuarter {
			width:100%;

			@include mq($from: tablet) {
				width:75%;
			}
		}


		padding:0;
		
		legend {
			border:1px solid $grey;
			@include font-size($base-font-size);
			display:none;
		}

		.fieldset-inner-container {
			margin:0;
			padding:20px;			
			@include clearfix;
		}

		.clone-block {
			overflow:hidden; 
			margin-bottom:30px;

			&:last-child {
				margin-bottom:0px;
			}
		}
	}
	
		
	.form-row-container {
		position:relative; 
		margin-bottom:30px;
		padding:0;
		// width:100%;
		@include clearfix();

		&:last-child {
			margin-bottom:0;
		}

		/* "other" title, multi-line address input */
		.extra-row {
			margin-top:5px;
		} 
		
		.counter-container {
			margin-bottom:10px;

			p {
				margin:0;
			}
		}

		&.with-button {
			@include mq($from: largephone) {
		        margin-right:150px!important;
		    }
			
			button {

				margin-top:5px;

				@include mq($from: largephone) {
			        margin-top:0;
					position:absolute; 
					bottom:0;
					right:-150px;
					float:right;
			    }
			}
		}


		/* Default values */
		input,
		select,
		textarea {
			margin:0;
			padding:0;
			@include font-size($base-font-size);

			&:focus {
				outline:0;
			}
		}

		
		/* Presentation */
		@include placeholder {
			line-height:1.2em;
			font-weight: $regular;
		}
		
		
		



		/* Override values */
		label {
			cursor:pointer; 
			@include font-size($base-font-size);

			&:focus {
				outline:1px solid red;
			}

		}
		
		.label {
			display:block;
			position:relative;
			font-weight:$bold;
			margin:0;
			padding:0;
			width:100%;
			cursor:pointer;
			@include font-size($base-font-size);
		}

		input[type="tel"],
		input[type="text"],
		input[type="email"],
		input[type="password"] {
			margin:0;
			padding:10px 10px 8px;
			width:100%;
			border:1px solid $grey;
		}

		input[type="radio"],
		input[type="checkbox"] {
			margin-right:5px;
			width:20px;
			height:20px;
		}

		
		select {
			padding:11px 10px 9px;
			cursor:pointer;
			color:$grey;
			border:1px solid $grey;
		}


		.select-style {
			margin-bottom:5px;
			width:100%;

			select {
				width:100%
			}

			@include mq($from: largephone) {
				select {
					width:auto;
				}
			}




			&.inline-at-large-phone {

				@include mq($from: largephone) {
					
					float:left;
					width:32%;
					margin-right:2%;
					
					&:last-child {
						margin-right:0;
					}

					select {
						width:100%;
					}
			    }
			}
		}		


		textarea {
			margin:0;
			padding:5px;
			width:100%;
			height:80px;
		}

	
		
		


		/* Icons */
		.required {
			position:absolute;
			top:5px;
			left:-10px;
			width:10px;
			height:10px;
			background-image:url(#{$path-form-images}required.png);
			background-position:0 0;
			background-repeat:no-repeat;
		}
		
		.tickIcon {
			position:absolute;
			top:50%;
			right:40px;
			margin-top:-20px;
			width:20px;
			height:40px;
			background-image:url(#{$path-form-images}tick.png);
			background-position:98% 50%;
			background-repeat:no-repeat;
		}
		
		.crossIcon {
			position:absolute;
			top:50%;
			right:40px;
			margin-top:-20px;
			width:20px;
			height:40px;
			background-image:url(#{$path-form-images}cross.png);
			background-position:98% 50%;
			background-repeat:no-repeat;
		}
		
		

		


		/* Wrappers and Icons */
		.inputWrapper {
			position:relative;
		}
			
		.clearIcon {
			display:none;
			position:absolute;
			top:3px;
			right:0;
			width:40px;
			height:40px;
			cursor:pointer; 
			z-index:3;
			background-image:url(#{$path-form-images}clear.png); 
			background-position:50% 50%;
			background-repeat:no-repeat;
		}
		
		.clearIconPadding {
			padding-right:28px!important
		}
		
		.passwordIcon {
			position:absolute;
			top:50%;
			right:5px;
			margin-top:-20px;
			width:35px;
			height:40px;
			cursor:pointer;
			z-index:3;
			background-image:url(#{$path-form-images}wink.png); 
			background-position:5px 50%;
			background-repeat:no-repeat;
		}
		
		.passwordIcon-show {
			background-position:-40px 50%
		}
		
		.movePasswordIcon {
			right:45px!important
		}
		
		.doubleIconPadding {
			padding-right:80px!important
		}
		
		
		
		/* Error / Help messages */
		.messageContainer {
			margin-top:5px;
			padding:6px 5px 5px 30px;

			p {				
				margin:0;
				padding:0;
			}
		}		
		
		.error-message {
			border:1px solid #df8f90;
			background-color:#ffd2d3;
			background-image:url(#{$path-form-images}cross.png);
			background-position:5px 50%;
			background-repeat:no-repeat;
		}
		
		.help-message {
			border:1px solid #9fc7f5;
			background-color:#dbecff;
			background-image:url(#{$path-form-images}help.png);
			background-position:5px 50%;
			background-repeat:no-repeat;
		}
	

		

		/* Info Panel - "data protection" */
		.infoPanel {
			position:relative; 
			margin:0;

			input {
				position:absolute; 
				top:0;
				left:0;
				margin:0;
				padding:0;
				border:0;
			}

			label {
				display:block;
				margin-left:25px;
				cursor:pointer;
			}
		}	
	}
	

/* RADIO / CHECKBOXES */			
/* e.g. Gender */
.compact-list {			
	margin:0;
	padding:0;
	list-style:none;

	li {
		display:inline-block; 
		margin:0;
		padding:5px 0 0;

		input {display:inline-block; vertical-align: middle}
		
		label {					
			display:inline-block;
			vertical-align: middle;
			margin-right:20px;
			padding:0;
		}
	}
}



/* Single Row List - e.g. Call me */
.single-row-list {
	margin:0;
	padding:0;
	list-style:none;

	li {				
		margin:0;
		padding:5px 0 0;
		@include mq($from: largephone) {
	        display:inline-block;
			width:49%;
	    }
		@include mq($from: tablet) {
	        display:inline-block;
			width:24%;
	    }

		input {display:inline-block; vertical-align: middle}

		label {
			display:inline-block; vertical-align: middle;
			margin-right:20px;
			padding:0;
		}
	}
}



/* Multi Col List - "Option list" */
.multi-col-list {
	margin:0;
	padding:0;
	list-style:none;

	
	@include mq($from: tablet) {
        li {
			// outline:1px solid red;
			display:inline-block;
			width:48%;
		}
    }
	@include mq($from: smalldesktop) {
        li {
        	// outline:1px solid lime;
			display:inline-block;
			width:30%;
		}
    }

	li {
		margin:0;
		padding:5px 0 0;

		input {
			display: inline-block;
			vertical-align: middle;
		}

		label {
			display: inline-block;
			vertical-align: middle;
			margin-right:20px;
			padding:0;
		}
	}
}


.block-list {
	margin:0;
	padding:0;
	list-style:none;

	li {
		display: block;
		margin:0 0 10px;
		padding:0;

		&:last-child {
			margin:0;
		}

		input {
			float:left;
			margin:2px 15px 0 0;
		}

		label {
			display:block;
			overflow:hidden;
			vertical-align: top;
			margin-right:20px;
			padding:0;
		}
	}
}


	
// Inline form elements
.one-line-form {
	@include clearfix;
	
	input,
	button {
		display: inline-block;
		margin: 0;
		vertical-align: middle;
	}
	
	input {
		width: 72%;
		border: 0;
		margin-right: 3%;
		padding: 12px 20px;
		background-color: white;
	}
	button {
		width: 25%;
		padding: 10px 0;
	}
}
