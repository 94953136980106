/*-----------------*/
/*	SEARCH RESULTS */
/*-----------------*/

	.search-results-summary {
		padding: 25px 0;
		margin-top: $normal-padd;
	}
	.search-results {
		margin-bottom:20px;
		padding:0;
		
		li {
			margin:15px 0 0;
			padding:15px 0 0;
			list-style-type: none;
			border-top: 1px solid $black;
			
			&:first-child {
				border-top: none;
			}
		}
	}

	.result__title {
		margin:0;

		a {
			color: $grey;
		}
	}

	.result__summary {
	margin:0;
	padding-bottom:7px;
	}

	.result__link {
		display: inline-block;
		color: $black;
		padding-bottom: 5px;
	}