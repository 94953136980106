/*---------------------*/
/*	FULL SCREEN BANNER */
/*---------------------*/

	.full-screen-banner {

		position: relative;

		&.tint {
			&:before {
				content:"";
				position: absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				background:rgba(0,0,0,0.3)
			}
		}
	}


	//Homepage masthead container
	.full-screen-banner-content-container {
		// border:3px solid lime;
		width: 90%; 
		margin:0 auto;
		display: table;
	}

	//content page masthead container
	.masthead {
		display: table;
	}


	.header-promo-container {
		position:absolute;
		bottom:20px;
		right:0;
		width:100px;
		height:100px;
		border-radius:500px;
		background: $red;

		@include mq($from: smalldesktop) {
			bottom:40px;
			right:20px;
			width:150px;
			height:150px;
		}
		

		.header-promo-content {
			@include centerer();
			text-align: center;
			margin:0;
			padding:0;
			font-family:$funzone-font;

			@include mq($from: smalldesktop) {
				@include font-size(2.5);
			}
		}
	}




	.full-screen-banner-content {

		text-align: center; 
		padding-top:140px;
		padding-bottom:20px;
		// border:2px solid cyan;

		@include mq($from: smallphone) {
		    // border:10px solid red;
		}

		@include mq($from: largephone) {
			// border:10px solid lime;
		    padding-top:110px;
		}

		@include mq($from: mobile, $and: '(min-height:500px)') {
			padding-top:220px;
		}

		@include mq($from: tablet) {
			// border:10px solid yellow;
		    // padding-top:175px;
		}

		@include mq($from: smalldesktop) {
			// border:10px solid orange;
		}

		@include mq($from: smalldesktop, $and: '(min-height:600px)') {
			// border:10px dashed orange;

			display:table-cell;
			vertical-align: middle;
			height:100vh;
			padding-top:200px;
		}

		.masthead & {
			@include mq($from: smalldesktop) {
				// border:10px solid blue;
				padding-top:0px;
				height:360px;
			}
		}


		h1 {
			margin:0;
			padding:0;
			font-weight:$light;
			text-align: center;
			text-shadow: 0px 0px 15px #000;

			.masthead & {
				text-align: left; 
			}

			@include mq($from: largephone, $and: '(min-height:600px)') {
			    @include font-size(6, 6);
			    text-align: left;
			}

			@include mq($from: desktop) {
			    @include font-size(7, 7);
			    text-align: left; 
			}

			.masthead & {
				@include mq($from: desktop) {
				    @include font-size(6, 6);
				    text-align: left; 
				}
			}
		}

		p {
			text-align: center;
			text-shadow: 0px 0px 15px #000;

			@include mq($from: largephone) {
			    @include font-size(2.5, 3);
			    text-align: left;
			}
			@include mq($from: desktop) {
			    @include font-size(3.5, 5);
			    text-align: left; 
			}
		}

		.scrollDownIcon {
			position: absolute;
			/*bottom:30px;
			margin-left:25px;
			height:80px;
			display:none;*/
			left:50%;

			@include mq($from: smalldesktop, $and: '(min-height:650px)') {
		    	display:block;
			}

			&:after {
				@include mq($from: largephone) {
			    	@include font-size(5,5); 
				}

				@include mq($from: desktop) {
			    	@include font-size(7,7); 
				}
			}
		}
	}

	


	div.masthead {

		div.full-screen-banner-content {

			h1, .subtitle { 
				margin-left: 50px;
				text-shadow: 0px 0px 15px #000;

				@include mq($from: smalldesktop) {
					margin-left: 150px;
				}
			}

			h1 {
				margin-top: 100px;
			}
		}
	}


	

	div.dartmoor-story {

		display:block;

		div.full-screen-banner-content {
			display:block;
			width:100%;
			text-align:center;
			padding-top:1px;

			h1 {
				margin-left: 0; 
				margin-top: 125px;
				text-align:center;
			}
			.subtitle {
				margin-left: 0;
				text-align:center;
				@include font-size(2.3)
			}
		}
	}




	.footer-banner-container {
		margin:0 auto 50px;

		.text-container {
			background-color: $grey;
			color:$white;
			padding:20px;
			h2, h3, p {
				margin:0;
				color:$white;
				text-align:center;
			}
			h2 + p,
			h3 + p {
				margin-top:20px;
			}
		}

		@include mq($from: smalldesktop) {
			position: relative;
			width:90%;
			
			.text-container {
				background-color: rgba($grey, 0.9);
				position: absolute;
				bottom:0;
				left:50%;
				margin-left:-30%;
				width:60%;
			}
		}
	}



	.dartmoorStory {
		padding:30px 0;	
		text-align: center;

		img {
			display:block;
			margin:0 auto 20px;

			&:last-child {
				margin-bottom:0;
			}
			@include mq($until: largephone) { 
				width:100%;
				height:auto;
			}

			@include mq($from: smalldesktop) {

				&.left {
					float:left;
					margin-bottom:0;
				}

				&.right {
					float:right;
					margin-bottom:0;
				}
			}
		}


	}

.home-main-img {
	height: 85vh;

	p {
		padding: 0px;
	}
}